body {
  background: #f3f3f3 !important;
  color: #000000 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow-x: hidden;
}

* {
  padding: 0;
  margin: 0;
}

/* ScrollBar Stylings*/
.table-container,
.friends {
  cursor: pointer;
  scrollbar-width: thin !important;
  scrollbar-color: black transparent !important;
}

.table-container::-webkit-scrollbar,
.friends::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important; /* The height is only seen on horizontal scrolling */
}

.table-container::-webkit-scrollbar-track,
.friends::-webkit-scrollbar-track {
  background: transparent;
  padding: 2px;
}

.table-container::-webkit-scrollbar-thumb,
.friends::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-content {
  width: 500px;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  padding: 50px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.component {
  position: fixed;
  top: 20%;
  right: 0;
  z-index: 1000;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.component.hidden {
  opacity: 0;
  transform: translate(-50%, -150%);
}